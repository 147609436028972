// import Vue from "vue";
import axios from "axios";
// import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import router from "@/router";
// import Swal from "sweetalert2";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    axios.defaults.baseURL = "https://api.ats-veteransaffairsvidb.com";
    // Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL =
    //   "https://6yu23m76hl.execute-api.us-west-2.amazonaws.com/Prod/";

    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (!error.response) {
          JwtService.destroyToken();
          router.push("/login");
        }
        // if (error?.response?.data?.message) {
        //   // Swal.fire({
        //   //   title: "",
        //   //   text: error?.response?.data?.message,
        //   //   icon: "error",
        //   //   confirmButtonClass: "btn btn-secondary",
        //   // });
        // }
        return Promise.reject(error);
      }
    );
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    axios.defaults.headers.common["Authorization"] = `${JwtService.getToken()}`;
  },

  query(resource, params) {
    return axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
