import ApiService from "@/core/services/api.service";
import axios from "axios";

// action types
export const GET_VETERANS_LIST = "getVeteransList";
export const CREATE_VETERAN = "createVeteran";
export const UPDATE_VETERAN = "updateVeteran";
export const DELETE_VETERAN = "deleteVeteran";
export const GET_VETERAN_DETAIL = "getVeteranDetail";
export const UPLOAD_VETERAN_DOCUMENT = "uploadVeteranDocument";
export const GET_VETERAN_DOCUMENTS_LIST = "getVeteranDocumentsList";
export const GET_DOCUMENT_PUBLIC_URL = "getDocumentPublicURL";
export const DELETE_VETERAN_DOCUMENT = "deleteVeteranDocument";
export const SEARCH_VETERANS_LIST = "searchVeteransList";

// import JwtService from "@/core/services/jwt.service";
// mutation types

const state = {};

const getters = {};

const actions = {
  [GET_VETERANS_LIST]() {
    return new Promise((resolve) => {
      ApiService.get(`veterans?limit=&nextpage`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          console.error(response);
        });
    });
  },
  [SEARCH_VETERANS_LIST](context, params) {
    return new Promise((resolve) => {
      ApiService.post(`search-veteran?limit=10`, params)
        .then(({ data }) => {
          resolve(data.result);
        })
        .catch(({ response }) => {
          console.error(response);
        });
    });
  },
  [CREATE_VETERAN](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post(`create-veteran`, params)
        .then(({ data }) => {
          resolve(data.result);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_VETERAN_DETAIL](context, userId) {
    return new Promise((resolve) => {
      ApiService.get(`veteran/${userId}`)
        .then(({ data }) => {
          resolve(data.result);
        })
        .catch(({ response }) => {
          console.error(response);
        });
    });
  },
  [UPDATE_VETERAN](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(`update-veteran`, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [DELETE_VETERAN](context, userId) {
    return new Promise((resolve) => {
      ApiService.delete(`veteran/${userId}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          console.error(response);
        });
    });
  },
  [UPLOAD_VETERAN_DOCUMENT](context, params) {
    const { userId, formData } = params;
    return new Promise((resolve) => {
      axios
        .post(`upload-file/${userId}`, formData, {
          headers: {
            "content-type": "application/octet-stream",
          },
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          console.error(response);
        });
    });
  },
  [GET_VETERAN_DOCUMENTS_LIST](context, userId) {
    return new Promise((resolve) => {
      ApiService.get(`veteran/${userId}/file`)
        .then(({ data }) => {
          resolve(data.result);
        })
        .catch(({ response }) => {
          console.error(response);
        });
    });
  },
  [GET_DOCUMENT_PUBLIC_URL](context, { fileId, userId }) {
    return new Promise((resolve) => {
      ApiService.get(`/file/${userId}`, fileId)
        .then(({ data }) => {
          resolve(data.result);
        })
        .catch(({ response }) => {
          console.error(response);
        });
    });
  },
  [DELETE_VETERAN_DOCUMENT](context, { fileId, userId }) {
    return new Promise((resolve) => {
      ApiService.delete(`file/${userId}/${fileId}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          console.error(response);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
