import ApiService from "@/core/services/api.service";

export const GET_DASHBOARD_STATS = "getDashboardStats";

const state = {};

const getters = {};

const actions = {
  [GET_DASHBOARD_STATS]() {
    return new Promise((resolve) => {
      ApiService.get(`dashboard`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          console.error(response);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
